<template>
  <section :class="['input_section']">
    <label
      v-click-outside="closeComment"
      :for="`input_id_${unique_id}`"
    >
      <p
        :id="`text_input_emit_name_${unique_id}`"
        class="input_name"
      >
        {{ label_name }}
        <span
          v-if="required"
          style="color: red"
          >*</span
        >
        <span v-if="filling_status">
          <img
            v-if="value"
            class="checkmark"
            src="@/assets/img/green-check.svg"
            alt=""
          />
          <img
            v-else
            class="checkmark no_filled"
            src="@/assets/img/black-check.svg"
            alt=""
          />
        </span>
      </p>
      <div :class="['text_input', validation_error ? 'input_error' : '', disabled ? 'disabled' : '']">
        <input
          :id="`input_id_${unique_id}`"
          :type="type"
          :value="value"
          :required="required"
          :disabled="disabled"
          :placeholder="placeholder"
          autocomplete="off"
          @input="$emit('update', field_name, $event.target.value)"
        />

        <div
          v-if="user.is_analyst && comment"
          class="icon"
        >
          <img
            src="@/assets/img/add_comment.svg"
            alt="add_comment"
            @click="show_comment = true"
          />
          <div
            v-if="commentsSorted.length"
            class="number_of_comments"
          >
            {{ commentsSorted ? commentsSorted.length : '' }}
          </div>
        </div>
      </div>
      <small
        v-if="hint"
        class="input_hint"
        >{{ hint }}</small
      >

      <div
        v-if="show_comment && comment && !user.is_client"
        class="comment_board"
      >
        <form @submit.prevent="addCommentHandle">
          <div class="add_comment">
            <!--            <div class="user">-->
            <!--              <div class="user_avatar">{{ user.username[0].toUpperCase() }}</div>-->
            <!--            </div>-->
            <input
              v-model="new_comment.text"
              required
              :placeholder="$t('general.add_comment')"
            />

            <div class="comment_buttons">
              <div
                :class="['add_button', is_comment_filled ? 'success' : '']"
                @click="addCommentHandle"
              >
                {{ $t('general.add') }}
              </div>
            </div>
          </div>
        </form>

        <!-- already created comments -->
        <div class="created_comments">
          <div
            v-for="(c, index) in commentsSorted"
            :key="index"
            class="detail_comment"
          >
            <!--            <div class="user">-->
            <!--              <div class="user_avatar">{{ c.create_by }}</div>-->
            <!--            </div>-->

            <div class="text">
              <div class="comment_title">
                <div
                  v-for="create_by in createByUser(c.create_by)"
                  :key="create_by.id"
                  class="info"
                >
                  <span class="name">{{ create_by.username }}</span>
                  <span class="date">{{ c.created.split('T')[0] }}</span>
                </div>

                <div class="action">
                  <button
                    class="delete_button"
                    type="button"
                    @click="delComment(c.id)"
                  >
                    {{ $t('general.delete') }}
                  </button>
                </div>
              </div>

              <p class="comment_text">
                {{ c.text }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p
        v-if="validation_error"
        class="error_text"
      >
        {{ validation_error }}
      </p>
      <!-- Проверяем орфографические ошибки в последнем слове, если нашли ошибку выводим список подходящих слов -->
      <div
        v-if="suggestions_words.length"
        class="fold"
      >
        <div class="data_wrapper">
          <div
            v-for="(element, index) in suggestions_words"
            :id="`default_elements_${index + 1}`"
            :key="index"
            class="data_el"
            @click="replaceElement(element)"
          >
            {{ element }}
          </div>
        </div>
      </div>

      <delete-modal
        :show="show_delete_comment"
        title="Удаление комментария"
        desc="Вы уверены, что хотите удалить данный комментарий?"
        @approve="handleDeleteComment"
        @close="show_delete_comment = false"
      ></delete-modal>
    </label>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DeleteModal from '@/components/global/modals/deleteModal.vue'
import { spellCheckWords } from '@/services/spell_check'
export default {
  name: 'TextInputEmit',
  components: { DeleteModal },

  props: {
    value: {
      type: [String, Number],
    },
    field_name: {
      type: String,
    },
    tagId: {
      type: String,
      default: '',
    },
    label_name: String,
    placeholder: String,
    required: Boolean,
    error: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
    },
    comment: String,
    query_param: Number,
    obj: String,
    filling_status: Boolean,
  },

  emits: ['update'],

  data() {
    return {
      unique_id: '',

      new_comment: {
        text: '',
      },
      show_delete_comment: false,
      current_comment_index: 0,

      show_comment: false,
      suggestions_words: [],
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['server', 'auth_headers', 'company']),
    ...mapState('company', ['company', 'comments', 'users']),
    ...mapState('process', ['process']),
    ...mapState('system', ['system']),
    ...mapState('user', ['user']),

    validation_error() {
      if (this.error && this.error.length) {
        return this.error
      }
      if (this.type === 'email' && this.value && this.value.length) {
        if (
          this.value.match(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g,
          )
        ) {
          return ''
        } else {
          return 'Введите корректный email'
        }
      }
      return ''
    },

    is_comment_filled() {
      return !!this.new_comment.text.length
    },

    commentsSorted() {
      let comment = this.comment
      let commentsSorted = [...this.comments]
      return commentsSorted.filter(c => c.field_name === comment && c.object_id === this.getObjectId)
    },

    getObjectId() {
      return this.obj === 'process' ? this.process.id : this.system.id
    },
  },

  watch: {
    value(newValue) {
      const words = newValue.trim().split(/\s+/)
      const last_word = words[words.length - 1]
      //Проверяем орфографические ошибки, если нашли ошибку в последнем слове передаем список подходящих слов
      if (newValue.endsWith(' ')) {
        this.suggestions_words = this.spellCheckWords(last_word)
      }
    },
  },

  methods: {
    spellCheckWords,
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },
    handleDeleteComment() {
      let cid = this.company.id
      let ci = this.current_comment_index
      this.deleteComment({ current_index: ci, company_id: cid })
      this.show_delete_comment = false
      this.show_comment = true
    },

    addCommentHandle() {
      this.show_comment = true
      if (this.new_comment.text?.length) {
        this.addComments({
          content_type: 9,
          create_by: parseInt(this.user.id),
          field_name: this.field_name,
          object_id: this.getObjectId,
          text: this.new_comment.text,
          company_id: this.company.id,
        })
      }
      this.new_comment.text = ''
    },

    createByUser(id) {
      return this.users.filter(user => user.id === id)
    },

    // validationWords(field_name, value) {
    //   //Проверяем слово на орфографические ошибки и предлагаем набор слов для замены
    //   this.$emit('update', field_name, value)
    //   this.suggestions_words = this.spellCheckWords(value.trim())
    // },

    replaceElement(element) {
      const words = this.value.trim().split(/\s+/)
      if (words.length > 0) {
        words[words.length - 1] = element
      }
      const new_value = words.join(' ')
      this.$emit('update', this.field_name, new_value)
      this.suggestions_words = []
    },

    delComment(id) {
      this.show_delete_comment = true
      this.current_comment_index = id
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
