import {api} from '@/main'
import i18n from '@/locales'

const emptyAnswer = {
  data: null,
  error: null,
  sessionTimeout: false,
}

export async function checkApiResponse(result, context, showError = true) {
  if (result.sessionTimeout && context.rootState.auth_type === 'login' && !context.rootState.user.is_public_mode) {
    context.commit('WARNING_TOAST', i18n.global.t('logout.session_expired'), { root: true })
    await context.dispatch('user/logout', null, { root: true })
    return false
  }

  if (!result.error) {
    return true
  }

  if (showError) {
    context.commit('DANGER_TOAST', result.error, { root: true })
  }

  return false
}

class BaseClient {
  constructor(company = null) {
    this.company = company
  }

  setLastActivity() {
    localStorage.setItem('lastActivity', String(Date.now()))
  }

  checkSessionTimeout() {
    // Возвращает true если сессия устарела
    const lastActivity = Number(localStorage.getItem('lastActivity'))
    const now = Date.now()
    return !!(lastActivity && now - lastActivity > 45 * 60 * 1000)
  }

  getUrl() {
    return `${api.api_url}/companies/${this.company}/`
  }

  getObjectUrl(id) {
    return `${this.getUrl()}${id}/`
  }

  getResult(result, expected_status = 200, error = null) {
    if (expected_status === 400) {
      return result
    }
    if (!error && result.status === expected_status) {
      return { ...emptyAnswer, data: result.data }
    }
    return { ...emptyAnswer, error: error?.response.data.detail }
  }

  async create(fields = {}) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    if (fields.length === 0) {
      return { ...emptyAnswer, error: 'Data is empty' }
    }
    this.setLastActivity()
    try {
      const result = await api.client.post(`${this.getUrl()}`, fields)
      return this.getResult(result, 201)
    } catch (error) {
      if (error.response.data?.freemium) {
        return this.getResult(error.response, 400, error)
      }
      return this.getResult(null, 201, error)
    }
  }

  async fetch(id) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()
    try {
      const result = await api.client.get(`${this.getObjectUrl(id)}`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async fetchAll(limit = null, offset = 0, ordering = null) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    let url = this.getUrl()
    if (limit || offset || ordering) {
      let params = []
      if (limit) {
        params.push(`limit=${limit}`)
      }
      if (offset) {
        params.push(`offset=${offset}`)
      }
      if (ordering) {
        params.push(`ordering=${ordering}`)
      }
      url += `?${params.join('&')}`
    }

    try {
      const result = await api.client.get(url)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async update(id, fields = {}, checkTimeout = true) {
    if (checkTimeout && this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    if (fields.length === 0) {
      return { ...emptyAnswer, error: 'Data is empty' }
    }
    try {
      const result = await api.client.patch(`${this.getObjectUrl(id)}`, fields)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }

  async delete(id) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    try {
      const result = await api.client.delete(`${this.getObjectUrl(id)}`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }
}

class BaseClientWithInit extends BaseClient {
  async init(id) {
    if (this.checkSessionTimeout()) {
      return { ...emptyAnswer, sessionTimeout: true }
    }
    this.setLastActivity()

    try {
      const result = await api.client.get(`${this.getObjectUrl(id)}init_data/`)
      return this.getResult(result, 200)
    } catch (error) {
      return this.getResult(null, 200, error)
    }
  }
}

export class CompanyClient extends BaseClientWithInit {
  getUrl() {
    return `${api.api_url}/companies/`
  }
}
// Process
export class ProcessClient extends BaseClientWithInit {
  getUrl() {
    return `${super.getUrl()}processes/`
  }
}

export class AddProcessClient extends BaseClient {
  getUrl() {
    return `${super.getUrl()}processes/`
  }
}

export class FetchAllProcessDataClient extends BaseClientWithInit {
  constructor(company, process) {
    super(company)
    this.process = process
  }
  getUrl() {
    return `${super.getUrl()}processes/${this.process}/init_data/`
  }
}
// Process positions
export class ProcessSubjectClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }
  getUrl() {
    return `${super.getUrl()}processes/${this.process}/process_subjects/`
  }
}

export class ProcessPositionClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/positions/`
  }
}

//Process source subjects
export class ProcessSourceSubjectClient extends BaseClientWithInit {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/source_subjects/`
  }
}

export class ProcessPublicSourceClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/public_sources/`
  }
}

export class ProcessOtherSourceClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/other_sources/`
  }
}

// Paper docs
export class ProcessPaperDocClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/paper_docs/`
  }
}

export class ProcessSystemsClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/process_systems/`
  }
}

export class ProcessSystemsSubjectClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/process_system_subjects/`
  }
}

export class ProcessThirdPartyClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process}/process_third_parties/`
  }
}

export class ProcessThirdPartySubjectsClient extends BaseClient {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    return `${super.getUrl()}processes/${this.process.id}/process_third_party_subjects/`
  }
}

export class SearchProcessesClient extends BaseClient {
  constructor(company, value) {
    super(company)
    this.value = value
  }

  getUrl() {
    return `${super.getUrl()}processes/?search=${this.value}`
  }
}

export class FilteredTagsProcessesClient extends BaseClientWithInit {
  constructor(company) {
    super(company)
  }

  getUrl() {
    return `${super.getUrl()}processes/`
  }
}

export class ActiveProcessClient extends BaseClientWithInit {
  constructor(company, process) {
    super(company)
    this.process = process
  }

  getUrl() {
    if (this.process.is_active) {
      return `${super.getUrl()}processes/inactive/${this.process.id}/`
    } else {
      return `${super.getUrl()}processes/`
    }
  }
}

// Systems
export class SystemClient extends BaseClientWithInit {
  getUrl() {
    return `${super.getUrl()}systems/`
  }
}

export class FetchAllSystemDataClient extends BaseClientWithInit {
  constructor(company, process) {
    super(company)
    this.system = process
  }
  getUrl() {
    return `${super.getUrl()}systems/${this.system}/init_data/`
  }
}

export class AddSystemClient extends BaseClient {
  getUrl() {
    return `${super.getUrl()}systems/`
  }
}

export class SystemOtherSourceClient extends BaseClient {
  constructor(company, system) {
    super(company)
    this.system = system
  }

  getUrl() {
    return `${super.getUrl()}systems/${this.system}/system_source_others/`
  }
}

export class SystemSubjectClient extends BaseClient {
  constructor(company, system) {
    super(company)
    this.system = system
  }
  getUrl() {
    return `${super.getUrl()}systems/${this.system}/system_subjects/`
  }
}

export class SystemThirdPartyClient extends BaseClient {
  constructor(company, system) {
    super(company)
    this.system = system
  }

  getUrl() {
    return `${super.getUrl()}systems/${this.system}/system_third_parties/`
  }
}

export class SystemThirdPartySubjectsClient extends BaseClient {
  constructor(company, system) {
    super(company)
    this.system = system
  }

  getUrl() {
    return `${super.getUrl()}systems/${this.system.id}/system_third_party_subjects/`
  }
}

export class SearchSystemsClient extends BaseClient {
  constructor(company, value) {
    super(company)
    this.value = value
  }

  getUrl() {
    return `${super.getUrl()}systems/?search=${this.value}`
  }
}

export class FilteredTagsSystemsClient extends BaseClient {
  getUrl() {
    return `${super.getUrl()}systems/`
  }
}

export class SystemClientInteractions extends BaseClient {
  constructor(company, system) {
    super(company)
    this.system = system
  }

  getUrl() {
    return `${super.getUrl()}systems/${this.system}/system_interactions/`
  }
}

//Company

export class HistoryClient extends BaseClient {
  constructor(company, offset, limit) {
    super(company)
    this.offset = offset
    this.limit = limit
  }
  getUrl() {
    return `${super.getUrl()}history/?offset=${this.offset}&limit=${this.limit}`
  }
}

export class EmployeeClient extends BaseClient {
  getUrl() {
    return `${super.getUrl()}employees/`
  }
}
