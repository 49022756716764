import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import {
  ProcessClient,
  checkApiResponse,
  ProcessSubjectClient,
  AddProcessClient,
  ProcessPositionClient,
  ProcessOtherSourceClient,
  ProcessPublicSourceClient,
  ProcessPaperDocClient,
  ProcessSourceSubjectClient,
  ProcessSystemsClient,
  ProcessSystemsSubjectClient,
  ProcessThirdPartyClient,
  ActiveProcessClient,
  ProcessThirdPartySubjectsClient,
  SearchProcessesClient,
  FetchAllProcessDataClient,
} from '@/api/clients'

export default {
  // Process actions
  async fetchProcesses(context, payload) {
    const processClient = new ProcessClient(payload.company_id)
    const result = await processClient.fetchAll(payload.limit, payload.offset)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async orderingProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?offset=${payload.offset}&limit=${payload.limit}&ordering=${payload.ordering}`,
      context,
      {},
      'SET_PROCESSES',
      // i18n.global.t('company.processes.loading_processes_error'),
    )
  },

  async fetchAllProcessData(context, payload) {
    const fetchAllData = new FetchAllProcessDataClient(payload.company, payload.process_id)
    const result = await fetchAllData.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_INIT_PROCESS_DATA', result.data)
    }
    await context.dispatch('company/fetchAllSystems', { company_id: payload.company }, { root: true })
    await context.dispatch('system/fetchSystems', { company_id: payload.company }, { root: true })
    await context.dispatch('company/fetchThirdParties', payload.company, { root: true })

    // if (!result) {
    //   await context.dispatch('fetchAllProcessData', payload)
    // }
  },

  async fetchProcess(context, payload) {
    // Получаем процесс
    const processClient = new ProcessClient(payload.company)
    const result = await processClient.fetch(payload.process_id)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async fetchDefaultProcesses(context, company_id) {
    // Получаем процессы по умолчанию
    await api.query(
      'GET',
      `/companies/${company_id}/processes/default_processes/`,
      context,
      {},
      'SET_DEFAULT_PROCESSES',
      // i18n.global.t('company.processes.loading_error'),
    )
  },

  async addProcess(context, payload) {
    // Добавляем процесс
    const addProcessClient = new AddProcessClient(payload.company)
    const result = await addProcessClient.create(payload)
    if (result.data?.id) {
      context.commit('ADD_PROCESS', result.data)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
      await context.dispatch('company/fetchCompanyTags', payload.company, { root: true })
      return result
    }
    if (result.data?.freemium) {
      // Превышен лимит анкет для демо компании
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
    } else {
      // Если не удается создать процесс
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.creating_error'), { root: true })
    }
  },

  async copyProcess(context, payload) {
    // Копирование процесса
    const result = await api.query(
      'CREATE',
      `/companies/${payload.company}/processes/${payload.process}/copy/`,
      context,
      payload,
      'ADD_PROCESS',
      i18n.global.t('company.processes.creating_error'),
    )
    if (result.status) {
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
    }
    // Обновляем анкету Отделы и должности если копируем данные ИСПДн, используемые в рамках копируемого процесса
    if (payload.is_copy_systems) {
      await context.dispatch('company/fetchDepartment', payload.company, { root: true })
    }
    return result
  },

  async deleteProcess(context, payload) {
    // Удаляет процесс
    const processClient = new ProcessClient(payload.company)
    const result = await processClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS', payload.id ? payload.id : payload.uuid)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_delete'), { root: true })
    }
  },

  updateProcess: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    let fields = {}
    fields[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const processClient = new ProcessClient(payload.form.company)
    const result = await processClient.update(
      payload.form.id,
      { ...fields, percent: context.getters.percent },
      !!context.state.user,
    )
    await checkApiResponse(result, context)
    if (result.error) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchProcess', { company: payload.form.company, process_id: payload.form.id })
    }
  }, 500),

  async deleteProcessDocument(context, payload) {
    // Обновляем поле анкеты
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    const processClient = new ProcessClient(payload.form.company)
    const result = await processClient.update(payload.form.id, { ...fields, percent: context.getters.percent })
    await checkApiResponse(result, context)
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от процесса
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async updateProcessName(context, payload) {
    context.commit('SET_PROCESS', payload.form)
    await context.dispatch('updateProcess', payload)
  },

  // Subject
  async fetchProcessSubjects(context, payload) {
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process_id)
    const result = await processSubjectClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SUBJECTS', result.data)
    }
  },

  async addSubject(context, payload) {
    // Добавляем субъекта
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process)
    const result = await processSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SUBJECT', result.data)
    }
    await context.dispatch('company/fetchSubjects', payload.company, { root: true })
  },

  async deleteSubject(context, payload) {
    // Удаляет субъект в процессе
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.process)
    const result = await processSubjectClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SUBJECT', payload.id)
    }
    await context.dispatch('company/fetchSubjects', payload.company, { root: true })
    payload = { ...payload, process_id: payload.process }
    await context.dispatch('fetchSourceSubjects', payload)
    await context.dispatch('fetchProcessSystemSubjects', payload)
    await context.dispatch('fetchProcessThirdPartySubjects', payload)
  },

  updateSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  }, 500),

  async deleteSubjectDocument(context, payload) {
    let uuids = {}
    uuids[payload.field_name] = payload.new_uuids
    uuids['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, uuids)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', uuids)
    }
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от субъекта
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async updateSubjectFields(context, payload) {
    // Обновляем поля действий в анкете
    let fields = {}
    fields = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient(payload.company, payload.form.process)
    const result = await processSubjectClient.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  },

  // Position actions
  async addPosition(context, payload) {
    const { company, process } = payload
    // Добавляем отдел
    const addPositionClient = new ProcessPositionClient(company, process)
    const result = await addPositionClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_POSITION', result.data)
    }
  },

  async deletePosition(context, payload) {
    const { company, process } = payload
    // Удаляет процесс
    const deletePositionClient = new ProcessPositionClient(company, process)
    const result = await deletePositionClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_POSITION', payload.id)
    }
  },

  // Субъекты привязанные к источникам
  async fetchSourceSubjects(context, payload) {
    const fetchSourceSubject = new ProcessSourceSubjectClient(payload.company, payload.process_id)
    const result = await fetchSourceSubject.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SOURCE_SUBJECTS', result.data)
    }
  },

  async addSourceSubject(context, payload) {
    // Добавляем субъект источника
    const { company, process } = payload
    const addSourceSubj = new ProcessSourceSubjectClient(company, process)
    const result = await addSourceSubj.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SOURCE_SUBJECT', result.data)
    }
  },

  async deleteSourceSubject(context, payload) {
    // Удаляет субъект источника
    const { company, process } = payload
    const delourceSubject = new ProcessSourceSubjectClient(company, process)
    const result = await delourceSubject.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SOURCE_SUBJECT', payload.id)
    }
  },

  updateSourceSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value
    const updateSourceSubj = new ProcessSourceSubjectClient(payload.company, payload.process)
    const result = await updateSourceSubj.update(payload.form.id, data)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SOURCE_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', { company: payload.company, process_id: payload.process })
  }, 100),

  // Публичные источники привязанные процессу
  async addPublicSource(context, payload) {
    const { company, process } = payload
    // Добавляем субъект источника
    const addPublicSourceClient = new ProcessPublicSourceClient(company, process)
    const result = await addPublicSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PUBLIC_SOURCE', result.data)
    }
  },

  async deletePublicSource(context, payload) {
    const { company, process } = payload
    const deletePublicSourceClient = new ProcessPublicSourceClient(company, process)
    const result = await deletePublicSourceClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PUBLIC_SOURCE', payload.id)
    }
  },

  // Другие источники привязанные процессу
  async addOtherSource(context, payload) {
    const { company, process } = payload
    const addOtherSourceClient = new ProcessOtherSourceClient(company, process)
    const result = await addOtherSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_OTHER_SOURCE', result.data)
    }
  },

  async deleteOtherSource(context, payload) {
    const { company, process } = payload
    const delOtherSource = new ProcessOtherSourceClient(company, process)
    const result = await delOtherSource.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_OTHER_SOURCE', payload.id)
    }
  },

  // Бумажные документы привязанные процессу
  async fetchPaperDocs(context, payload) {
    const { company, process } = payload
    const fetchPaperDoc = new ProcessPaperDocClient(company, process)
    const result = await fetchPaperDoc.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PAPER_DOCS', result.data)
    }
  },

  async addPaperDocs(context, payload) {
    const { company, process } = payload
    const addPaperDoc = new ProcessPaperDocClient(company, process)
    const result = await addPaperDoc.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PAPER_DOCS', result.data)
    }
    if (result) {
      return result
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.error_name'), { root: true })
    }
  },

  async deletePaperDocs(context, payload) {
    const { company, process } = payload
    const paperDocClient = new ProcessPaperDocClient(company, process)
    const result = await paperDocClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PAPER_DOC', payload.id)
    }
  },

  async updatePaperDoc(context, payload) {
    // // Обновляем документ
    let data = {}
    data[payload.field_name] = payload.value
    const processPaperDocument = new ProcessPaperDocClient(payload.company, payload.process)
    const result = await processPaperDocument.update(payload.form.id, data)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PAPER_DOC', result.data)
    }
  },

  // Системы привязанные процессу
  async fetchProcessSystems(context, payload) {
    const { company, process } = payload
    const fetchProcessSys = new ProcessSystemsClient(company, process)
    const result = await fetchProcessSys.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SYSTEMS', result.data)
    }
  },

  async addProcessSystem(context, payload) {
    const { company, process } = payload
    const addProcessSys = new ProcessSystemsClient(company, process)
    const result = await addProcessSys.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SYSTEM', result.data)
    }
  },

  async deleteProcessSystem(context, payload) {
    const { company, process } = payload
    const deleteProcessSystems = new ProcessSystemsClient(company, process)
    const result = await deleteProcessSystems.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SYSTEM', payload.id)
    }
  },

  async updateProcessSystem(context, payload) {
    // Обновляем систему
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessSys = new ProcessSystemsClient(payload.company, payload.process)
    const result = await updateProcessSys.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SYSTEM', result.data)
    }
    if (payload.field_name === 'positions') {
      await context.dispatch('company/fetchDepartment', payload.company, { root: true })
    }
  },

  // Субъекты системы привязанной к процессу
  async fetchProcessSystemSubjects(context, payload) {
    const { company, process } = payload
    const fetchProcessSystemsSubject = new ProcessSystemsSubjectClient(company, process)
    const result = await fetchProcessSystemsSubject.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SYSTEM_SUBJECTS', result.data)
    }
  },

  async addProcessSystemSubject(context, payload) {
    const { company, process } = payload
    const addProcessSystemsSubj = new ProcessSystemsSubjectClient(company, process)
    const result = await addProcessSystemsSubj.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SYSTEM_SUBJECT', result.data)
    }
  },

  async deleteProcessSystemSubject(context, payload) {
    const { company, process } = payload
    const deleteProcessSystemsSubj = new ProcessSystemsSubjectClient(company, process)
    const result = await deleteProcessSystemsSubj.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SYSTEM_SUBJECT', payload.id)
    }
  },

  updateProcessSystemSubject: _.debounce(async (context, payload) => {
    // Обновляем систему
    let fields = {
      process_system: payload.process_system,
      subject: payload.subject,
    }
    fields[payload.field_name] = payload.value
    const updateProcessSystemsSubj = new ProcessSystemsSubjectClient(payload.company, payload.process)
    const result = await updateProcessSystemsSubj.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SYSTEM_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', { company: payload.company, process_id: payload.process })
  }, 100),

  async fetchProcessThirdParties(context, payload) {
    const fetchSProcessThirdPartyClient = new ProcessThirdPartyClient(payload.company, payload.process)
    const result = await fetchSProcessThirdPartyClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_THIRD_PARTIES', result.data)
    }
  },

  async addProcessThirdParty(context, payload) {
    const { company, process } = payload
    const addProcessThParty = new ProcessThirdPartyClient(company, process)
    const result = await addProcessThParty.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_THIRD_PARTY', result.data)
    }
  },

  async deleteProcessThirdParty(context, payload) {
    const { company, process } = payload
    const deleteProcessThParty = new ProcessThirdPartyClient(company, process)
    const result = await deleteProcessThParty.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_THIRD_PARTY', payload.id)
    }
  },

  updateProcessThirdParty: _.debounce(async (context, payload) => {
    // Обновляем третье лицо в процессе
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessThParty = new ProcessThirdPartyClient(payload.company, payload.process)
    const result = await updateProcessThParty.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_THIRD_PARTY', result.data)
    }
  }, 1000),

  async deleteDocumentThirdParty(context, payload) {
    let uuids = {}
    uuids[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_PROCESS_THIRD_PARTY', { ...uuids, id: payload.form.id })

    const result = await api.query(
      'UPDATE',
      `/companies/${payload.company}/processes/${payload.process}/process_third_parties/${payload.form.id}/`,
      context,
      uuids,
      null,
      '',
    )
    if (!result.error) {
      //Удаляем документ, если смогли отвязать
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async fetchProcessThirdPartySubjects(context, payload) {
    const fetchProcessThirdPartySubj = new ProcessThirdPartySubjectsClient(payload.process.company, payload.process)
    const result = await fetchProcessThirdPartySubj.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_THIRD_PARTY_SUBJECTS', result.data)
    }
  },

  async addProcessThirdPartySubject(context, payload) {
    const addProcessThirdPartySubj = new ProcessThirdPartySubjectsClient(payload.process.company, payload.process)
    const result = await addProcessThirdPartySubj.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_THIRD_PARTY_SUBJECT', result.data)
    }
  },

  async deleteProcessThirdPartySubject(context, payload) {
    const delProcessThirdPartySubj = new ProcessThirdPartySubjectsClient(payload.process.company, payload.process)
    const result = await delProcessThirdPartySubj.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_THIRD_PARTY_SUBJECT', payload.id)
    }
  },

  updateProcessThirdPartySubject: _.debounce(async (context, payload) => {
    // Обновляем субъект третьего лица
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessThirdPartySubj = new ProcessThirdPartySubjectsClient(payload.process.company, payload.process)
    const result = await updateProcessThirdPartySubj.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_THIRD_PARTY_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', { company: payload.process.company, process_id: payload.process.id })
  }, 100),

  async searchProcesses(context, payload) {
    const searchProcess = new SearchProcessesClient(payload.company_id, payload.search)
    const result = await searchProcess.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('FILTER_PROCESSES', result.data)
    }
  },

  async filteredTagsProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'SET_PROCESSES',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },

  // Запрашиваем неактивные процессы
  async fetchInactiveProcesses(context, payload) {
    await api.query('GET', `/companies/${payload.company_id}/processes/inactive`, context, {}, 'SET_INACTIVE_PROCESSES')
  },

  //Переместить процесс в активные
  async moveProcessToActive(context, payload) {
    const moveActiveProcess = new ActiveProcessClient(payload.company, payload)
    const result = await moveActiveProcess.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SUCCESS_TOAST', i18n.global.t('company.inactive_processes.move_active'), { root: true })
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.inactive_processes.move_error'), { root: true })
    }
    await context.dispatch('fetchProcesses', { company_id: payload.company, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company })
  },

  async moveProcessToInactive(context, payload) {
    //Переместить процесс в неактивные
    const moveProcess = new ActiveProcessClient(payload.company, payload)
    const result = await moveProcess.update(payload.id, payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SUCCESS_TOAST', i18n.global.t('company.inactive_processes.move_active'), { root: true })
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.inactive_processes.move_error'), { root: true })
    }
    await context.dispatch('fetchProcesses', { company_id: payload.company, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company })
  },
}
