//Сервис для проверки орфографических ошибок в слове
import Typo from 'typo-js'
import affData from '@/dictionaries/ru_RU.aff'
import dicData from '@/dictionaries/ru_RU.dic'
const ruDictionary = new Typo('ru_RU', affData, dicData, { platform: 'any' })

export const spellCheckWords = value => {
  // ruDictionary.check() Проверка в словаре typo-js
  if (value && !ruDictionary.check(value)) {
    // Если нашли слово с ошибкой передаем список слов подходящих для замены
    return ruDictionary.suggest(value)
  }
  return []
}
